<template>
  <div class="add-new-courier">
    <title-bar
      title="Update courier"
      submitBtn
      submitBtnTitle="Save courier"
      @submitPressed="submitPressed"
    />
  <courier-form entity="Courier" redirect="/couriers" v-if="courier" :userObject="courier" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import CourierForm from '../Employees/components/EmployeeForm.vue'
import router from '@/router'

export default {
  data() {
    return {
      action: null,
      courier: null
    }
  },
  components: {
    CourierForm,
    TitleBar,
  },
  created() {
    this.loadCourier()
  },
  methods: {
    loadCourier() {
      const { id } = router.currentRoute.params
      this.$Users.getResource({ id }).then(response => {
        this.courier = response.data
      })
    },
    submitPressed() {
      this.action = 'update'
    },
  },
}
</script>
